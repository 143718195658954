export default [
  {
    header: 'Dataset',
  },
  {
    title: 'Contract',
    route: 'jccd-contract',
    icon: 'LayersIcon',
  },
  {
    title: 'Customer',
    route: 'jccd-customer',
    icon: 'UsersIcon',
  },
  {
    title: 'Voucher',
    route: 'jccd-voucher',
    icon: 'TagIcon',
  },
  { 
    header: 'Mint Page'
  },
  {
    title: 'Voucher Mint',
    route: 'voucher',
    icon: 'TagIcon',
  },
]
